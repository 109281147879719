<template>
    <div class="container-special">
      <div class="row justify-content-between align-items-end">
        <div class="col-12 col-md-8">
          <div class="row justify-content-between">
            <div class="col-12 col-md-4 d-flex align-items-end">
              <p class="our-site">Emiter Sp. z o.o.</p>
            </div>
            <div class="col-6 col-md-4 d-flex justify-content-start align-items-sm-end">
              <p class="mb-0 contact">Porcelanowa 27<br/>
                40-241 Katowice</p>
              </div>
              <div class="col-6 col-md-4">
                <a class="contact" href="tel:+48327303400">+48 32 730 34 00</a>
                <br/>
                <a class="contact" href="mailto:emiter@emiter.net.pl">emiter@emiter.net.pl</a>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 d-flex align-items-end justify-content-start justify-content-md-end">
            <a class="our-site" href="https://emiter.net.pl/">www.emiter.net.pl</a>
          </div>    
        </div>
      </div>
</template>

<script>
export default {
  name: 'footerComponent',
}
</script>

<style scoped lang="scss">

.our-site{
  font-weight: bold;
  font-size: 20px;
  color: #000;
  margin-bottom: 2px;
}

.contact{
  text-decoration: none;
  color: #000;
}

@media only screen and (max-width: 980px) {
  .our-site{
    font-size: 18px;
  }
  .contact{
    font-size: 14px;
  }
}

@media only screen and (max-width: 890px) {
  .container-special{
  padding: 10px 30px;
}
}
</style>
