<template>
  <div class="header">
    <HeaderComponent />
  </div>
  <div class="content">
    <AllContentComponentVue />
  </div>
  <div class="footer">
    <FooterComponentVue />
  </div>
</template>

<script>
import "@/style/bootstrap-grid.min.css";
import "../src/style/bootstrap-icons.css"
import HeaderComponent from './components/HeaderComponent.vue'
import AllContentComponentVue from './components/AllContentComponent.vue';
import FooterComponentVue from './components/FooterComponent.vue';

export default {
  name: 'App',
  data(){
    return{

    }
  },  
  components: {
    HeaderComponent,
    AllContentComponentVue,
    FooterComponentVue
    
  }
}
</script>

<style lang="scss">

@font-face {
  font-family: Monto;
  src: url('./style/fonts/Montserrat-VariableFont_wght.ttf') format("truetype")
}

*{
  font-family: Monto;
}

.container-special{
  padding: 5px 87px;
}

.header {
  background-image: linear-gradient(to right, #4E4E4E, #8C8C8C);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 78px;
  color: #fff;
  border-bottom: 2px solid #f6f9fc;
}

.content {
  margin-top: 78px;
}
.footer {
  height: 75px;
}

@media only screen and (max-width: 760px) {
  .container-special{
  padding: 10px 20px;
}
}
</style>
