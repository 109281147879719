import { createApp } from 'vue'
import App from './App.vue'
import { createI18n } from "vue-i18n";

function loadLocaleMessages() {
    const locales = require.context(
      "./locales",
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};
    locales.keys().forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = locales(key);
      }
    });
    return messages;
  }
  function languageOfBrowser() {
    let localLang = localStorage.getItem("language");
    if (localLang && localLang.toLowerCase() == 'pl') {
      return 'pl'
    }
    else if (localLang && localLang.toLowerCase() == 'en') {
      return 'en'
    }
    else if (localLang && localLang.toLowerCase() == 'de') {
        return 'de'
      }
    else {
      if (navigator.language == "en-EN") {
        return "en";
      } else if (navigator.language == "de-DE") {
        return "de";
      }
      else {
        return "pl";
      }
    }
  }

  const i18n = createI18n({
    locale: languageOfBrowser(), // set locale
    // allowComposition: true,
    fallbackLocale: navigator.language || "pl", // set fallback locale
    messages: loadLocaleMessages(),
    interpolation: {
        escapeValue: false,
      }
  });
createApp(App).use(i18n).mount('#app')
