<template>
  <div class="container-special">
    <div class="row fill-height align-items-center justify-content-between">
      <div class="col-5 col-lg-3 col-md-3 col-lg-2 d-flex justify-content-start align-items-center">
        <img src="../assets/logo-white-new.png" alt="logo" />
      </div>
      <div class="col-6 col-md-5 d-none d-md-flex align-items-center justify-content-center">
        <p class="term mr-4" v-html="$t('header.stand')"></p>
        <div>
          <p class="term" v-html="$t('header.term')"></p> <p class="term" v-html="$t('header.hour')"></p>
        </div>
      </div>
      <div
        class="col-7 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-start justify-content-lg-end align-items-center">
        <div class="row w-100">
          <div class="col d-flex justify-content-between align-items-center">
            <img src="../assets/logo_forum_battery_poland.png" alt="forum_battery" class="m-2" />
            <img src="../assets/ptak_warsaw_expo.png" alt="warsaw_expo" class="m-2" />
            <div class="nav-wrapper">
              <div class="sl-nav">
                <ul>
                  <li><b>{{ actualLanguage }}</b>
                    <ul>
                      <li @click="changeLanguage('pl')" :class="actualLanguage == 'PL' ? 'active' : ''">PL</li>
                      <li :class="actualLanguage == 'EN' ? 'active' : ''" @click="changeLanguage('en')">EN</li>
                      <li :class="actualLanguage == 'DE' ? 'active' : ''" @click="changeLanguage('de')">DE</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'headerComponent',
  computed: {
    actualLanguage: {
      get() {
        return (this.$i18n.locale).toUpperCase()
      }
    }
  },
  methods: {
    changeLanguage(lang) {
      localStorage.setItem("language", lang);
      this.$i18n.locale = lang;
    },
  }
}
</script>

<style scoped lang="scss">
.fill-height {
  height: 100%;
}

.container-special {
  .term {
    font-weight: normal !important;
    margin-bottom: 0px;
    font-size: 20px;
    line-height: 24px;
  }
}

img[alt="logo"] {
  max-height: 40px;
}

img[alt="forum_battery"],
[alt="warsaw_expo"] {
  max-height: 50px;
}

.nav-wrapper {
  margin: 5px 20px;
}

.sl-nav {
  display: inline;
}

.sl-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
}

.sl-nav li {
  cursor: pointer;
  padding-bottom: 10px;
}

.sl-nav li ul {
  display: none;
}

.sl-nav li:hover ul {
  position: absolute;
  top: 29px;
  right: -15px;
  display: block;
  background: #fff;
  padding-top: 0px;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.sl-nav li ul li {
  position: relative;
  text-align: left;
  background: transparent;
  padding: 15px 15px;
  padding-bottom: 0;
  z-index: 2;
  font-size: 15px;
  color: #3c3c3c;
}

.sl-nav li ul li:last-of-type {
  padding-bottom: 15px;
}

.sl-nav li ul li span {
  padding-left: 5px;
}

.sl-nav li ul li:hover,
.sl-nav li ul li.active {
  font-weight: bold;
}

@media only screen and (max-width: 900px) {
  .container-special {
    padding: 10px 30px;
  }

  img[alt="logo"] {
    max-height: 30px;
  }

  img[alt="forum_battery"],
  [alt="warsaw_expo"] {
    max-height: 40px;
  }

  .container-special {
    .term {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 510px) {
  .container-special {
    padding: 0px 0px;
    margin: 10px 10px;
  }

  img[alt="logo"] {
    max-height: 30px;
  }

  img[alt="forum_battery"],
  [alt="warsaw_expo"] {
    max-height: 40px;
  }
}

@media only screen and (max-width: 450px) {
  img[alt="logo"] {
    max-height: 25px;
  }

  img[alt="forum_battery"],
  [alt="warsaw_expo"] {
    max-height: 35px;
  }
}
</style>
