<template>
  <div class="container-special mb-3">
    <div
      class="d-flex align-items-center justify-content-around d-md-none b2 h4 text-center w-100 mb-0 p-0 p-sm-2 stand-info">
      <p class="h5" v-html="$t('header.stand')"></p>
      <div>
        <p class="h5 mb-1" v-html="$t('header.term')"></p>
        <p class="h5" v-html="$t('header.hour')"></p>
      </div>
    </div>
    <div class="row justify-content-center justify-content-md-around">
      <div class="col-12 col-lg-5 col-xl-4 d-flex justify-content-center p-0 p-sm-3 p-relative box-main-image">
        <img src="../assets/uwe-hensel-1.png" alt="hensel-1" width="100%" height="100%" />
        <div class="inner-info">
          <p class="h4">{{ $t('content.box1') }}</p>
        </div>
      </div>
      <div class="col-12 col-lg-5 col-xl-4 d-flex justify-content-center p-0 p-sm-3  p-relative box-main-image">
        <img src="../assets/uwe-hensel-2.png" alt="hensel-2" width="100%" height="100%" />
        <div class="inner-info">
          <p class="h4">{{ $t('content.box2') }}</p>
        </div>
      </div>
      <div class="col-12 col-lg-5 col-xl-4 d-flex justify-content-center p-0 p-sm-3 p-relative box-main-image">
        <img src="../assets/uwe-hensel-3.png" alt="hensel-3" width="100%" height="100%" />
        <div class="inner-info">
          <p class="h4">{{ $t('content.box3') }}</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col-12 col-sm-10">
        <p class="h3 text-left text-sm-center invite"><span>{{ $t('content.inviteInfo1') }}</span> <span>{{
        $t('content.inviteInfo2') }}</span> {{ $t('content.inviteInfo3') }} </p>
      </div>
    </div>
    <div class="row justify-content-center align-items-center my-5 bckg-g">
      <div class="col-12 text-box d-flex justify-content-center align-items-center">
        <img class="col-12 col-md-8 col-lg-5 speed-img" src="../assets/speed_meeting.svg" />
      </div>
    </div>
    <div class="row border-black p-4">
      <div class="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end px-1 px-md-3">
        <p class="partner mb-3 mb-md-0">partner:</p>
      </div>
      <div class="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start px-1 px-md-4">
        <img class="ml-0 ml-md-3" width="auto" height="100%" src="../assets/huawei_logo.png"/>
      </div>
    </div>
    <div class="row justify-content-between justify-content-lg-around pb-3">
      <div class="col-12 col-md-6 py-3 py-md-0 align-items-start">
        <p class="speed-description h5 h-lg-4 text-right pt-2 py-md-3">{{ $t('content.description') }}</p>
      </div>
      <div class="col-12 col-md-6 d-flex align-items-start py-3 py-md-0">
        <p class="h3 h-lg-2 p-3 m-md-3 text-left">{{ $t('content.join') }}</p>
      </div>
    </div>
    
    <div class="row join-us pt-3 pt-md-5">
      <div class="col">
        <div class="row justify-content-between">
          <div class="col-12 col-lg-6 px-3 px-sm-4 px-lg-5 d-flex justify-content-start justify-content-lg-end">
            <p class="term-info text-right font-white"><span v-html="$t('header.term')"></span>, <span
                v-html="$t('header.hour')"></span></p>
          </div>
          <div class="col-6 px-1 px-md-5 d-none d-lg-flex">
            <p class="h5 font-white"><span class="h5" v-html="$t('header.stand')"></span><br /> PTAK WARSAW EXPO</p>
          </div>
        </div>
        <div class="row justify-content-start">
          <div class="col-12 col-lg-6 p-3 p-sm-4 p-lg-5">

            <h4 class="font-white">{{ $t('modal.title') }}</h4>
            <form method="POST" action="https://emiter.net.pl/wp-json/speed-meeting">
              <div class="row">
                <div class="col-12 widthBg">
                  <label for="company">{{ $t('modal.company') }}</label>
                  <input :placeholder="$t('modal.company')" required type="text" name="company" v-model="company"
                    @change="companyValidation" :class="errors.company == '' ? '' : 'errorInfo'" />
                  <label class="error-message" v-if="errors.company != ''" for="company">{{ errors.company
                    }}</label>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-6 widthBg">
                  <label for="name">{{ $t('modal.name') }}</label>
                  <input :placeholder="$t('modal.name')" type="text" required name="name" v-model="name"
                    @change="nameValidation" :class="errors.name == '' ? '' : 'errorInfo'" />
                  <label class="error-message" v-if="errors.name != ''" for="name">{{ errors.name }}</label>
                </div>
                <div class="col-12 col-sm-6 widthBg ">
                  <label for="surname">{{ $t('modal.surname') }}</label>
                  <input required :placeholder="$t('modal.surname')" type="text" name="surname" v-model="surname"
                    @change="surnameValidation" :class="errors.surname == '' ? '' : 'errorInfo'" />
                  <label class="error-message" v-if="errors.surname != ''" for="surname">{{ errors.surname
                    }}</label>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-6 widthBg">
                  <label for="email">Email</label>
                  <input placeholder="Email" type="email" required name="email" v-model="email"
                    @change="emailValidation" :class="errors.email == '' ? '' : 'errorInfo'" />
                  <label class="error-message" v-if="errors.email != ''" for="email">{{ errors.email }}</label>
                </div>
                <div class="col-12 col-sm-6 widthBg">
                  <label for="phone">{{ $t('modal.phone') }}</label>
                  <input :placeholder="$t('modal.phone')" required type="text" name="phone" v-model="phone"
                    @change="phoneValidation" :class="errors.phone == '' ? '' : 'errorInfo'" />
                  <label class="error-message" v-if="errors.phone != ''" for="phone">{{ errors.phone }}</label>
                </div>
              </div>
              <div class="row">
                <div class="col-12 widthBg select-date">
                  <label>{{ $t('modal.mettingDate') }}</label>
                  <Multiselect name="date" required :placeholder="$t('modal.datelist')" class="multiselect"
                    :options="listOfDates" :close-on-select="true" v-model="meetingDate" mode="single"
                    @input="selectDateFromList($event)" :class="errors.data == '' ? '' : 'errorInfo'"></Multiselect>
                  <label class="error-message" v-if="errors.data != ''">{{ errors.data }}</label>
                </div>
              </div>
              <div class="row mt-2">
                <button type="submit" @click.prevent="sendRequest" class="button m-3">{{ $t('modal.join') }}</button>
              </div>
            </form>
          </div>
          <div class="col-12 col-lg-6 p-3 p-sm-4 p-lg-5">
            <div class="col-12 px-1 px-lg-5 d-flex d-lg-none">
              <p class="h5 font-white"><span class="h5" v-html="$t('header.stand')"></span><br /> PTAK WARSAW EXPO</p>
            </div>
            <img src="../assets/stoisko_wybrane.jpg" width="100%" height="auto" />
          </div>
        </div>
      </div>
    </div>
    <div class="row green-border mt-5">
      <div class="col-12 col-md-6 d-flex align-items-center">
        <p class="text-right h5 mr-0 mr-md-5">{{ $t('content.info') }}</p>
      </div>
      <div class="col-12 col-md-6 d-flex align-items-center">
        <img class="pl-0 pl-md-5" src="../assets/uwe-hensel-4.jpg" width="100%" height="auto" />
      </div>
    </div>
  </div>
</template>

<script>
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";

export default {
  name: 'allContentComponent',
  components: {
    Multiselect
  },
  data() {
    return {
      email: null,
      name: null,
      surname: null,
      company: null,
      phone: null,
      listOfDates: ["22-05-2024 12:00", "23-05-2024 12:00"],
      meetingDate: null,
      errors: {
        company: "",
        name: "",
        surname: "",
        email: "",
        phone: "",
        data: "",
      },
    }
  },
  methods: {
    selectDateFromList(event) {
      this.meetingDate = event;
      this.dateValidation();
    },
    nameValidation() {
      if (this.name && this.name.length >= 2) {
        this.errors.name = "";
      } else {
        this.errors.name = this.$t('modal.requiredField');
      }
    },
    surnameValidation() {
      if (this.surname && this.surname.length >= 2) {
        this.errors.surname = "";
      } else {
        this.errors.surname = this.$t('modal.requiredField');
      }
    },
    companyValidation() {
      if (this.company && this.company.length > 2) {
        this.errors.company = "";
      } else {
        this.errors.company = this.$t('modal.requiredField');
      }
    },
    emailValidation() {
      if (this.email && this.email.length > 2 && this.email.includes("@")) {
        this.errors.email = "";
      } else {
        this.errors.email = this.$t('modal.requiredField');
      }
    },
    phoneValidation() {
      if (this.phone && this.phone.length > 2) {
        this.errors.phone = "";
      } else {
        this.errors.phone = this.$t('modal.requiredField');
      }
    },
    dateValidation() {
      if (this.meetingDate) {
        this.errors.data = "";
      } else {
        this.errors.data = this.$t('modal.selectDate');
      }
    },
    async sendRequest() {
      this.nameValidation();
      this.surnameValidation();
      this.companyValidation();
      this.phoneValidation();
      this.emailValidation();
      this.dateValidation();
      if (
        this.name &&
        this.surname &&
        this.company &&
        this.email &&
        this.phone &&
        this.meetingDate
      ) {
        try {

          await fetch("https://emiter.net.pl/wp-json/speed-meeting", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username: this.name,
              surname: this.surname,
              companyName: this.company,
              email: this.email,
              phone: this.phone,
              selectedDate: this.meetingDate,
              keyId: 'eqe5kn@^b2%pmi1_#ta*hgiy$4.24@-te$5git.'
            }),

          });
          alert(this.$t('modal.alertOk'))
          this.$emit("close", false);

        } catch (e) {
          alert(this.$t('modal.alertError'))
          console.error(e);
        }
      } else {
        alert(this.$t('modal.alertError'));
      }
    },
  }
}
</script>

<style scoped lang="scss">
img[alt="hensel-1"],
[alt="hensel-2"],
[alt="hensel-3"] {
  position: relative;
}

.p-relative {
  position: relative;
}

.inner-info {
  position: absolute;
  top: 20%;
  left: 1px;
  background-color: rgba(#5D9D1B, .8);
  width: 70%;
  height: 60%;
  display: flex;
  align-items: center;
  padding: 20px 30px;

  p {
    text-align: end;
    font-weight: bold;
    color: #ffff;
  }
}

.term-info {
  font-size: 30px;
}

.bckg-g {
  background: rgba(93, 157, 27);
}

.text-box {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;

  .speed-img {
    padding: 20px;
    color: white;
    position: relative;
    margin: 0px;
    animation: move-in-steps 3.5s infinite;
  }
}

@keyframes move-in-steps {
  0% {
    left: -50%;
    opacity: 0.5;
  }

  20% {
    opacity: 1;
  }

  40% {
    left: 0;
    right: 0;
  }

  60% {
    left: 0;
    right: 0;
  }

  80% {
    opacity: 1;
  }

  100% {
    left: 80%;
    opacity: 0;
  }
}
.partner{
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 0px;
}

.border-black{
  border: 2px solid #000;
  margin-bottom: 30px;
}
.join-us {
  background-image: linear-gradient(to right, #4E4E4E, #8C8C8C);

}

.font-white {
  color: #fff;
}

.speed-description {
  font-weight: normal !important;
}

.test {
  min-width: 100px;
}

.sing-up {
  font-size: 26px;
  margin-bottom: 0px;
}

.green-border {
  border-image: linear-gradient(45deg, #6BB41F, #666666) 1;
  border-width: 4px;
  border-style: solid;
  padding: 30px;
}

.click-area {
  cursor: pointer;
}

.b2 {
  color: #8D8D8D;
}

.button {
  display: inline-block;
  border-radius: 8px;
  border: 2px solid #ffff;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  text-decoration: none;
  line-height: 1.5;
  color: #fff;
  background-color: #4E4E4E;
  cursor: pointer;
  padding: 5px 10px;
  transition: background-color 0.5s, color 0.5s;
}

.button:hover {
  background-color: #666666;
}


.widthBg {
  width: 100%;
}

.errorInfo {
  border: 1px solid red;
}

.error-message {
  color: red;
  margin: 0px;
}

label {
  padding-left: 5px;
  margin: 15px 0 3px 0px;
  font-size: 12px;
  color: #ffff;
}

input[type="number"],
input[type="date"],
input[type="email"],
input[type="text"] {
  width: 100%;
  font-size: 12px;
}

input,
.input-tmp {
  &.multiple-elements {
    .selected-element {
      .mdi {
        cursor: pointer;
      }

      font-size: 12px;
      border: solid 1px #dee7f4;
      margin-right: 5px;
      margin-bottom: 5px;
      cursor: default;
      display: inline-block;
    }
  }

  &.single-element {
    font-size: 12px;

    .mdi {
      cursor: pointer;
      line-height: inherit;
    }

    display: flex;
    justify-content: space-between;
  }

  line-height: 30px;
  padding: 5px;
  border-radius: 0.5rem;
  border: solid 1px #dee7f4;
  background-color: #fff;
}

.multiselect {
  margin: unset;
  border: 1px solid #dee7f4;
  --ms-option-bg-selected: #4E4E4E;
  --ms-option-bg-selected-pointed: #4E4E4E;
  box-shadow: none;
  font-size: 14px;
}

.multiselect.is-active {
  box-shadow: none !important;
}

@media only screen and (max-width: 890px) {
  .sing-up {
    font-size: 22px;

  }

  .green-border {
    padding: 20px 10px;
  }
}

@media only screen and (max-width: 575px) {
  .inner-info {
    left: -1px;
  }
}

@media only screen and (max-width: 430px) {
  .inner-info p {
    font-size: 20px !important;
  }

  .stand-info {
    font-size: 20px;
  }

  .box-main-image {
    padding: 10px 0 !important;
  }

  .invite {
    font-size: 24px;

    span {
      font-weight: bold;
    }
  }

  .term-info {
    font-size: 22px;
  }

  .text-box {
    .speed-img {
      padding: 5px;
    }
  }
}
</style>
